import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function DevOpsEngineer () {
  return (
    <CareerLayout title='DevOps engineer' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a DevOps engineer at Trexity, you’ll be part of an expanding team and will be exposed to many areas of our platform. You will work on areas such as continuous delivery for our mobile apps and services, and overall service management. You will collaborate closely with the product and engineering teams, taking part in architectural and design discussions, scope planning, and customer conversations.</p>

      <h2>What you might do</h2>
      <ul className='trx-bullet mb'>
        <li>Make improvements to our continuous delivery pipeline for mobile apps, and platform services</li>
        <li>Manage and provision failover and automated backups for platform services</li>
        <li>Improve usage reporting and service status reporting</li>
        <li>Implement strategies to keep services running with as little disruption as possible</li>
        <li>Work on improvements to service deployment and implementation (e.g. blue-green deploys, multi-zone failover, etc.)</li>
        <li>Participate in architecture discussions, provide feedback and/or learning from the engineering team</li>
        <li>Resolve support requests as needed</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>You have at least 3 years of experience in a DevOps role or managing service delivery and maintenance</li>
        <li>You are proficient with the command line, Git and leveraging CI and CD for service delivery and maintenance</li>
        <li>You have extensive experience with cloud technologies and databases particularly GCP (e.g. GCP, AWS, Azure, Postgres, Firestore)</li>
        <li>Experience working in a fast growth environment</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
